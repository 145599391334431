<template>
  <a-modal v-model="showPopup" title="换绑手机号" :width="500" @cancel="handleCancel" @ok="handleConfirm">
    <div class="popup-content">
      <a-form-model v-bind="layout">
        <a-form-item label="新手机号">
          <a-input
              v-model="params.phoneNo"
              placeholder="请输入新绑定手机号"
              type="tel"
          />
        </a-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import { putUserChangePhoneApi } from "@/views/cmsPage/dqPlatform/_apis"
import {validatePhoneNumberStrict} from "@/untils/otherEvent"

export default {
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      params: {
        userId: undefined,
        phoneNo: undefined,
      },
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data) {
      this.params.userId = data.userId
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        userId: undefined,
        phoneNo: undefined,
      }
      this.showPopup = false
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.params.phoneNo) return this.$message.warn("请填新的手机号")
      if (!validatePhoneNumberStrict(this.params.phoneNo)) return this.$message.warn("请输入正确的手机号")
      this.$loading.show()
      const res = await putUserChangePhoneApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('修改成功')
      this.$emit('success')
      this.handleCancel()
    },
  }
}
</script>