var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "search-attr" }, [
        _c(
          "div",
          [
            _c("a-input", {
              staticStyle: { width: "160px" },
              attrs: {
                allowClear: "",
                size: "small",
                placeholder: "昵称或店铺名或userId"
              },
              model: {
                value: _vm.params.searchName,
                callback: function($$v) {
                  _vm.$set(_vm.params, "searchName", $$v)
                },
                expression: "params.searchName"
              }
            }),
            _c("a-input", {
              staticStyle: { width: "160px", "margin-left": "10px" },
              attrs: {
                allowClear: "",
                size: "small",
                placeholder: "用户IdNumber搜索"
              },
              model: {
                value: _vm.params.idNumber,
                callback: function($$v) {
                  _vm.$set(_vm.params, "idNumber", $$v)
                },
                expression: "params.idNumber"
              }
            }),
            _c("a-input", {
              staticStyle: { width: "150px", "margin-left": "10px" },
              attrs: {
                allowClear: "",
                size: "small",
                placeholder: "请输入手机号"
              },
              model: {
                value: _vm.params.userPhone,
                callback: function($$v) {
                  _vm.$set(_vm.params, "userPhone", $$v)
                },
                expression: "params.userPhone"
              }
            }),
            _c("a-range-picker", {
              staticStyle: { width: "250px", margin: "5px" },
              attrs: { placeholder: ["注册", "日期筛选"], size: "small" },
              model: {
                value: _vm.times,
                callback: function($$v) {
                  _vm.times = $$v
                },
                expression: "times"
              }
            }),
            _c("a-range-picker", {
              staticStyle: { width: "250px", margin: "5px" },
              attrs: { placeholder: ["最后登录", "日期筛选"], size: "small" },
              model: {
                value: _vm.lgTimes,
                callback: function($$v) {
                  _vm.lgTimes = $$v
                },
                expression: "lgTimes"
              }
            }),
            _c(
              "a-select",
              {
                staticStyle: { width: "100px", margin: "5px" },
                attrs: { allowClear: "", size: "small", placeholder: "粉丝" },
                model: {
                  value: _vm.params.fansSort,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "fansSort", $$v)
                  },
                  expression: "params.fansSort"
                }
              },
              [
                _c("a-select-option", { attrs: { value: 0 } }, [
                  _vm._v("正序")
                ]),
                _c("a-select-option", { attrs: { value: 1 } }, [_vm._v("倒序")])
              ],
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "100px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "账户金额"
                },
                model: {
                  value: _vm.params.availableMoneySort,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "availableMoneySort", $$v)
                  },
                  expression: "params.availableMoneySort"
                }
              },
              [
                _c("a-select-option", { attrs: { value: 0 } }, [
                  _vm._v("正序")
                ]),
                _c("a-select-option", { attrs: { value: 1 } }, [_vm._v("倒序")])
              ],
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "100px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "担保金额"
                },
                model: {
                  value: _vm.params.freezingMoneySort,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "freezingMoneySort", $$v)
                  },
                  expression: "params.freezingMoneySort"
                }
              },
              [
                _c("a-select-option", { attrs: { value: 0 } }, [
                  _vm._v("正序")
                ]),
                _c("a-select-option", { attrs: { value: 1 } }, [_vm._v("倒序")])
              ],
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "100px", margin: "5px" },
                attrs: { allowClear: "", size: "small", placeholder: "被赞" },
                model: {
                  value: _vm.params.otherLikeSort,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "otherLikeSort", $$v)
                  },
                  expression: "params.otherLikeSort"
                }
              },
              [
                _c("a-select-option", { attrs: { value: 0 } }, [
                  _vm._v("正序")
                ]),
                _c("a-select-option", { attrs: { value: 1 } }, [_vm._v("倒序")])
              ],
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "100px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "注册类型"
                },
                model: {
                  value: _vm.params.loginType,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "loginType", $$v)
                  },
                  expression: "params.loginType"
                }
              },
              [
                _c("a-select-option", { attrs: { value: "dq-platform" } }, [
                  _vm._v("小程序")
                ]),
                _c("a-select-option", { attrs: { value: "dq-app" } }, [
                  _vm._v("App")
                ]),
                _c("a-select-option", { attrs: { value: "dq-Album" } }, [
                  _vm._v("计泉")
                ]),
                _c("a-select-option", { attrs: { value: "dq-gzh-dqqb" } }, [
                  _vm._v("公众号")
                ])
              ],
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "100px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "来源渠道"
                },
                model: {
                  value: _vm.params.source,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "source", $$v)
                  },
                  expression: "params.source"
                }
              },
              [
                _c("a-select-option", { attrs: { value: "quanhai" } }, [
                  _vm._v("泉海")
                ])
              ],
              1
            ),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record, n) {
                return n
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "Image",
                fn: function(row) {
                  return _c("div", { staticClass: "flex-colum-start-center" }, [
                    _c(
                      "div",
                      { staticClass: "w-50 h-50 user-img flex-center-center" },
                      [
                        _c("img", {
                          staticClass: "w-50 h-50",
                          attrs: { src: row.userAvatar, alt: "" }
                        }),
                        row.userLevel
                          ? _c("div", { staticClass: "vip-tag" }, [
                              _c("img", {
                                staticClass: "vip-img",
                                attrs: { src: _vm.userVipIcon[row.userLevel] }
                              })
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-10" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.handleShowUserOfferPriceList(row)
                              }
                            }
                          },
                          [_vm._v("出价记录")]
                        )
                      ],
                      1
                    )
                  ])
                }
              },
              {
                key: "rank",
                fn: function(row) {
                  return _c("div", {}, [
                    row.isAttention == "0"
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(row.userName))
                        ])
                      : _c("span", [_vm._v(_vm._s(row.userName))]),
                    _c(
                      "div",
                      _vm._l(row.ranks, function(_item, _index) {
                        return _c(
                          "a-tag",
                          {
                            key: _index,
                            attrs: { closable: "" },
                            on: {
                              close: function($event) {
                                return _vm.confirmDelRankTag(
                                  $event,
                                  _item,
                                  _index
                                )
                              },
                              click: function($event) {
                                return _vm.editRankTag(_item, _index)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_item.rankName))]
                        )
                      }),
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.editRankTag(row)
                              }
                            }
                          },
                          [_vm._v("+头衔")]
                        )
                      ],
                      1
                    )
                  ])
                }
              },
              {
                key: "userPhone",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer" },
                        style: { color: row.isGrayUser == 1 ? "gray" : "blue" },
                        on: {
                          click: function($event) {
                            return _vm.handleShowUserPhone(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.userPhone))]
                    )
                  ])
                }
              },
              {
                key: "idSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.openLog(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.userId))]
                    )
                  ])
                }
              },
              {
                key: "Num",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.openPage(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.onShelfProduct))]
                    )
                  ])
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      row.isMute == "0"
                        ? _c(
                            "a",
                            {
                              staticStyle: { "margin-left": "5px" },
                              on: {
                                click: function($event) {
                                  return _vm.muteAction(row, "show")
                                }
                              }
                            },
                            [_vm._v("禁言")]
                          )
                        : _vm._e(),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "确认要执行操作吗?",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.muteAction(row)
                            }
                          }
                        },
                        [
                          row.isMute != "0"
                            ? _c(
                                "a",
                                { staticStyle: { "margin-left": "5px" } },
                                [_vm._v("取消禁言")]
                              )
                            : _vm._e()
                        ]
                      ),
                      row.userStatus == 2
                        ? _c(
                            "a",
                            {
                              staticStyle: { "margin-left": "5px" },
                              on: {
                                click: function($event) {
                                  return _vm.blackAction(row)
                                }
                              }
                            },
                            [_vm._v("取消拉黑")]
                          )
                        : _vm._e(),
                      row.userStatus == 1
                        ? _c(
                            "a",
                            {
                              staticStyle: { "margin-left": "5px" },
                              on: {
                                click: function($event) {
                                  return _vm.openBlackList(row)
                                }
                              }
                            },
                            [_vm._v("拉黑")]
                          )
                        : _vm._e(),
                      _c(
                        "a",
                        {
                          staticStyle: { "margin-left": "5px" },
                          on: {
                            click: function($event) {
                              return _vm.openBlackPenalize(row)
                            }
                          }
                        },
                        [_vm._v("惩罚")]
                      )
                    ],
                    1
                  )
                }
              },
              {
                key: "itemDqAndLianLianPriceSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c("div", { staticClass: "color-blue font-weight-bold" }, [
                      _vm._v("斗泉钱包：￥" + _vm._s(row.availableMoney))
                    ]),
                    row.lianLianMoney
                      ? _c(
                          "div",
                          { staticClass: "color-green font-weight-bold mt-10" },
                          [_vm._v("连连钱包：￥" + _vm._s(row.lianLianMoney))]
                        )
                      : _vm._e(),
                    row.lianLianMoney
                      ? _c(
                          "div",
                          { staticClass: "mt-10" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "danger", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleShowLianLianChangePrice(
                                      row
                                    )
                                  }
                                }
                              },
                              [_vm._v("代扣扣除")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    (row.availableMoney && row.availableMoney > 0) ||
                    (row.lianLianMoney && row.lianLianMoney > 0)
                      ? _c(
                          "div",
                          { staticClass: "mt-10" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleShowDrawLine(row)
                                  }
                                }
                              },
                              [_vm._v("明细")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { centered: "", width: 500 },
          on: { ok: _vm.checkForms, cancel: _vm.cancel },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { height: "200px", "overflow-y": "auto" } },
            [
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "ruleForm",
                    attrs: { model: _vm.forms, rules: _vm.rules }
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "名称", prop: "rankName" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入名称" },
                        model: {
                          value: _vm.forms.rankName,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "rankName", $$v)
                          },
                          expression: "forms.rankName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "图标" } },
                    [
                      _c(
                        "a-input",
                        {
                          attrs: { allowClear: "" },
                          on: {
                            paste: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.untils.pasteImg(
                                $event,
                                2,
                                _vm.parsetCallback
                              )
                            }
                          },
                          model: {
                            value: _vm.forms.rankIcon,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "rankIcon", $$v)
                            },
                            expression: "forms.rankIcon"
                          }
                        },
                        [
                          _c(
                            "a-upload",
                            {
                              attrs: {
                                slot: "addonBefore",
                                name: "file",
                                showUploadList: false,
                                action: _vm.ip + "/file/upload"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleChange($event, _vm.forms)
                                }
                              },
                              slot: "addonBefore"
                            },
                            [
                              _c(
                                "a",
                                [_c("a-icon", { attrs: { type: "upload" } })],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "排序" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入序号" },
                        model: {
                          value: _vm.forms.rankSort,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "rankSort", $$v)
                          },
                          expression: "forms.rankSort"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: { width: 450, centered: "", footer: null },
          on: { cancel: _vm.cancel },
          model: {
            value: _vm.userlogshow,
            callback: function($$v) {
              _vm.userlogshow = $$v
            },
            expression: "userlogshow"
          }
        },
        [
          _c("div", { style: "max-height:400;overflow:auto;" }, [
            _c("div", { staticClass: "modal-items" }, [
              _c("div", { staticClass: "title", attrs: { align: "center" } }, [
                _vm._v("登录信息")
              ]),
              _c("div", { staticClass: "modal-content modal-express" }, [
                _c("div", { staticClass: "basic-item" }, [
                  _vm._v("登录次数：" + _vm._s(_vm.userLog.loginCount))
                ]),
                _c("div", { staticClass: "basic-item" }, [
                  _vm._v("客户端类型：" + _vm._s(_vm.userLog.appType || ""))
                ]),
                _c("div", { staticClass: "basic-item" }, [
                  _vm._v("来源类型：" + _vm._s(_vm.userLog.bizType || ""))
                ]),
                _c("div", { staticClass: "basic-item" }, [
                  _vm._v("应用版本：" + _vm._s(_vm.userLog.appVersion || ""))
                ]),
                _c("div", { staticClass: "basic-item" }, [
                  _vm._v("登录IP：" + _vm._s(_vm.userLog.ip || ""))
                ]),
                _c("div", { staticClass: "basic-item" }, [
                  _vm._v("设备型号：" + _vm._s(_vm.userLog.deviceModel || ""))
                ]),
                _c("div", { staticClass: "basic-item" }, [
                  _vm._v("系统版本：" + _vm._s(_vm.userLog.appSystem || ""))
                ])
              ])
            ])
          ])
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "惩罚", "ok-text": "确认", "cancel-text": "取消" },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.blackpenalizeshow,
            callback: function($$v) {
              _vm.blackpenalizeshow = $$v
            },
            expression: "blackpenalizeshow"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.forms, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "惩罚标题", prop: "penalizeTitle" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "惩罚标题" },
                    model: {
                      value: _vm.blackPenalizeForms.penalizeTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.blackPenalizeForms, "penalizeTitle", $$v)
                      },
                      expression: "blackPenalizeForms.penalizeTitle"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "惩罚内容", prop: "penalizeContent" } },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "惩罚内容" },
                    model: {
                      value: _vm.blackPenalizeForms.penalizeContent,
                      callback: function($$v) {
                        _vm.$set(_vm.blackPenalizeForms, "penalizeContent", $$v)
                      },
                      expression: "blackPenalizeForms.penalizeContent"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "惩罚天数", prop: "days" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.blackPenalizeForms.days,
                        callback: function($$v) {
                          _vm.$set(_vm.blackPenalizeForms, "days", $$v)
                        },
                        expression: "blackPenalizeForms.days"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: "30" } }, [
                        _vm._v("1个月")
                      ]),
                      _c("a-select-option", { attrs: { value: "90" } }, [
                        _vm._v("3个月")
                      ]),
                      _c("a-select-option", { attrs: { value: "180" } }, [
                        _vm._v("6个月")
                      ]),
                      _c("a-select-option", { attrs: { value: "365" } }, [
                        _vm._v("12个月")
                      ]),
                      _c("a-select-option", { attrs: { value: "3650" } }, [
                        _vm._v("永久")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "拉黑", "ok-text": "确认", "cancel-text": "取消" },
          on: { cancel: _vm.resetForms, ok: _vm.blackConfirm },
          model: {
            value: _vm.blackshow,
            callback: function($$v) {
              _vm.blackshow = $$v
            },
            expression: "blackshow"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.forms, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "拉黑原因", prop: "penalizeContent" } },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "拉黑原因" },
                    model: {
                      value: _vm.blackForms.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.blackForms, "remark", $$v)
                      },
                      expression: "blackForms.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "连连钱包代扣扣除" },
          on: {
            cancel: _vm.handleLianLianChangeCancel,
            ok: _vm.handleLianLianChangeConfirm
          },
          model: {
            value: _vm.lianLianChangeShowModal,
            callback: function($$v) {
              _vm.lianLianChangeShowModal = $$v
            },
            expression: "lianLianChangeShowModal"
          }
        },
        [
          _c(
            "a-form-model",
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "扣除金额", prop: "money" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入扣除金额", type: "number" },
                    model: {
                      value: _vm.lianLianChangeForms.deductMoney,
                      callback: function($$v) {
                        _vm.$set(_vm.lianLianChangeForms, "deductMoney", $$v)
                      },
                      expression: "lianLianChangeForms.deductMoney"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "扣除描述", prop: "remarks" } },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "扣除描述", rows: 4 },
                    model: {
                      value: _vm.lianLianChangeForms.deductDesc,
                      callback: function($$v) {
                        _vm.$set(_vm.lianLianChangeForms, "deductDesc", $$v)
                      },
                      expression: "lianLianChangeForms.deductDesc"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("UserDrawLine", { ref: "userDrawLineEl" }),
      _c("UserBanSpeakPopup", {
        ref: "userBanSpeakPopupEl",
        on: { addSuccess: _vm.handleAddSuccessBanSpeak }
      }),
      _c("UserOfferPriceListPopup", { ref: "userOfferPriceListPopupEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }