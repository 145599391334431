import instance from "@/axios"

/**  获取用户出价记录--当前出价 */
export const getUserOfferPriceByCurrentApi = async (params) => {
    return instance({
        url: '/dq_admin/productUserBid/getCurrentBidList',
        method: 'GET',
        params: params
    })
}
/**  获取用户出价记录--历史出价 */
export const getUserOfferPriceByHistoryApi = async (params) => {
    return instance({
        url: '/dq_admin/productUserBid/getHistoryBidList',
        method: 'GET',
        params: params
    })
}