
/** 推送消息模版列表
 * templateParams: 参数配置
 * paramsValue: 参数属性
 * defaultParamsValue: 参数默认值
 * isSearchDescParams: 是否作为查询返回数据参数
 * paramsName: 参数名称
 * placeholder: 参数描述
 * actionPutType: 参数键入类型，inputText: 输入框文本类型，radioList: 单选框类型，
 * actionPutType: TODO：可能会在之后有的 inputNumber, inputTextarea, selectList
 * */
export const PUSH_MESSAGE_TEMPLATE_LIST = [
    {
        templateId: '52400492',
        templateName: '转评级北京抽检通知',
        templateParams: [
            {
                paramsValue: 'orderNo', // 参数属性
                defaultParamsValue: undefined, // 参数默认值
                isSearchDescParams: true, // 是否作为查询返回数据参数
                paramsName: '订单号', // 参数名称
                placeholder: '请输入订单号', // 参数描述
                actionPutType: 'inputText', // 参数键入类型，inputText: 输入框文本类型，radioList: 单选框类型
            },
            {
                paramsValue: 'type',  // 参数属性
                defaultParamsValue: 1, // 参数默认值
                isSearchDescParams: false, // 是否作为查询返回数据参数
                paramsName: '选择发送给谁',
                placeholder: '',
                actionPutType: 'radioList',
                radioList: [ //  类型为  radioList 时的选择项
                    { value: 1, name: '买家' },
                    { value: 2, name: '卖家' },
                    { value: 3, name: '买卖双方' }
                ]
            },
        ]
    },
]

/** 输入框类型映射 */
export const INPUT_TYPE_MAP_TYPE = {
    inputText: 'text',
    inputNumber: 'number',
    inputTextarea: 'textarea',
}