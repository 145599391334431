<template>
  <a-modal v-model="showPopup" title="消息模版推送" :maskClosable="false" :width="700" @cancel="handleCancel">
    <template #footer>
      <div></div>
    </template>
    <a-form-model :model="params" v-bind="layout">
      <a-form-item label="选择消息模版">
        <a-select
            class="w-400 ml-5"
            v-model="params.templateId"
            placeholder="请选择消息推送模版"
            @change="handleChangeTemplate"
        >
          <a-select-option
              v-for="item of pushMessageTemplateList"
              :key="item.templateId"
              :value="item.templateId"
          >{{ item.templateName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-for="item in templatePutParamsList" :key="item.paramsValue" :label="item.paramsName">
<!--  inputText 文本输入框      -->
        <a-input
            v-if="item.actionPutType === 'inputText'"
            :placeholder="item.placeholder"
            v-model="params[item.paramsValue]" :type="INPUT_TYPE_MAP_TYPE[item.actionPutType]"
        />
<!--  radioList 单选列表      -->
        <a-radio-group
            v-if="item.actionPutType === 'radioList'"
            v-model="params[item.paramsValue]"
            button-style="solid"
            @change="handleChangeRadioGroup"
        >
          <a-radio-button
              v-for="radio in item.radioList"
              :key="radio.value" :value="radio.value"
          >{{ radio.name }}</a-radio-button>
        </a-radio-group>
      </a-form-item>
<!--   确定信息   -->
      <a-form-item v-if="searchDescInfo" label="确定信息">
        <div class="desc-box">
          <div class="flex-start-start font-weight-bold color-black">
            <div class="left-title">订单编号：</div>
            <div class="right-content color-blue">{{ searchDescInfo.orderNo }}</div>
          </div>
          <div class="flex-start-start font-weight-bold color-black">
            <div class="left-title">钱币名称：</div>
            <div class="right-content color-blue">{{ searchDescInfo.productName }}</div>
          </div>
          <div class="flex-start-center img-list-box">
            <img v-for="(item, index) in searchDescInfo.cutCoverUrlList" :key="index" :src="item" alt=""
                @click="handleShowBigImg(searchDescInfo.cutCoverUrlList, index)"
            >
          </div>
          <div class="flex-start-start font-weight-bold color-black mb-5">
            <div class="left-title">发送短信内容：</div>
            <div class="right-content color-red">{{ searchDescInfo.desc }}</div>
          </div>
        </div>
      </a-form-item>
<!--   底部按钮   -->
      <a-form-item v-if="params.templateId" :label="searchDescInfo ? '请继续操作' : '请先确定发送信息'">
        <div v-if="searchDescInfo" class="flex-start-center">
          <a-popconfirm title="确定发送吗？" @confirm="handleConfirm">
            <a-button type="primary">确定模版，继续发送</a-button>
          </a-popconfirm>
          <a-button class="ml-40" @click="handleCancel">取消发送</a-button>
        </div>
        <div v-else>
          <a-button type="danger" @click="handleSearchDescInfo">获取发送确认信息</a-button>
        </div>
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>
<script>

import {
  INPUT_TYPE_MAP_TYPE,
  PUSH_MESSAGE_TEMPLATE_LIST
} from "@/views/cmsPage/dqPlatform/components/PushUserMessageTemplate/_data"
import {
  getMessageTemplateDetailInfoApi,
  getUserWhitelistMessageTemplateApi
} from "@/views/cmsPage/dqPlatform/components/PushUserMessageTemplate/_apis"
import _showBigImgMixin from "@/_mixins/_showBigImgMixin"

export default {
  mixins: [_showBigImgMixin],
  data() {
    return {
      INPUT_TYPE_MAP_TYPE,
      pushMessageTemplateList: PUSH_MESSAGE_TEMPLATE_LIST, // 推送信息模版列表
      showPopup: false,
      layout: {
        labelCol: { span: 6 }, //标签占5份  共24份
        wrapperCol: { span: 18 },
      },
      isSearchLoading: false,
      searchDescInfo: undefined,
      searchDescParams: {
        templateId: undefined, // 模版ID
      },
      preTemplateId: undefined, // 上一次的模版ID
      params: {
        templateId: undefined, // 模版ID
        userId: undefined, // 用户ID
      },
      templatePutParamsList: undefined,
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data) {
      this.params.userId = data.userId
      this.showPopup = true
    },
    /** 确定 */
    async handleConfirm() {
      if (this.templatePutParamsList && this.templatePutParamsList.length) {
        for (let i = 0; i < this.templatePutParamsList.length; i++) {
          if (!this.params[this.templatePutParamsList[i].paramsValue]) {
            this.$message.warn(`请填写${this.templatePutParamsList[i].paramsName}`)
            return
          }
        }
      }
      this.$loading.show()
      const res = await getUserWhitelistMessageTemplateApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('推送成功')
      this.$emit('editorSuccess', this.params.userId)
      this.handleCancel()
    },
    /** 改变模版 */
    handleChangeTemplate(e) {
      if (e === this.preTemplateId) return
      this.handleClearParams()
      this.$nextTick(() => {
        const found = this.pushMessageTemplateList.find(item => item.templateId === e)
        if (found) {
          this.templatePutParamsList = found.templateParams
          found.templateParams.forEach(item => {
            this.params[item.paramsValue] = item.defaultParamsValue
          })
          console.log(this.params, 'found.templateParams')
        } else {
          this.$message.warn('暂未找到该模版，联系技术')
        }
        this.preTemplateId = e
      })
    },
    /** 清空数据 */
    handleClearParams() {
      this.params = {
        templateId: this.params.templateId, // 模版ID
        userId: this.params.userId, // 用户ID
      }
      this.searchDescParams = {
        templateId: this.params.templateId, // 模版ID
      }
      this.isSearchLoading = false
      this.searchDescInfo = undefined
      this.templatePutParamsList = undefined
    },
    /** 取消 */
    handleCancel() {
      this.isSearchLoading = false
      this.searchDescInfo = undefined
      this.searchDescParams = {
        templateId: undefined, // 模版ID
      }
      this.preTemplateId = undefined // 上一次的模版ID
      this.params = {
        templateId: undefined, // 模版ID
        userId: undefined, // 用户ID
      }
      this.templatePutParamsList = undefined
      this.showPopup = false
    },
    async handleSearchDescInfo() {
      if (this.templatePutParamsList && this.templatePutParamsList.length) {
        for (let i = 0; i < this.templatePutParamsList.length; i++) {
          if (!this.params[this.templatePutParamsList[i].paramsValue] && this.templatePutParamsList[i].isSearchDescParams) {
            this.$message.warn(`请填写${this.templatePutParamsList[i].paramsName}`)
            return
          }
        }
      }
      this.templatePutParamsList.forEach(item => {
        if (item.isSearchDescParams) {
          this.searchDescParams[item.paramsValue] = this.params[item.paramsValue]
        }
      })

      if (this.isSearchLoading) return this.$message.info('正在获取中...')
      this.isSearchLoading = true
      this.$loading.show()
      const res = await getMessageTemplateDetailInfoApi(this.searchDescParams)
      this.isSearchLoading = false
      this.$loading.hide()
      if (res.status !== '200') return
      this.searchDescInfo = res.data
    },
    /** 关闭数据 */
    handleChangeRadioGroup(e) {
      this.$forceUpdate()
    }
  }
}
</script>
<style scoped lang="scss">
.desc-box {
  padding: 0 10px;
  width: 100%;
  border-radius: 10px;
  background-color: #E1EAEC;
}
.img-list-box {
  width: 100%;
  img {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    margin-right: 5px;
  }
}
.left-title {
  width: 100px !important;
}
.right-content {
  margin-top: 10px;
  width: calc(100% - 100px) !important;
  line-height: 25px;
}
</style>