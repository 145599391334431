<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input
          allowClear
          v-model="params.searchName"
          size='small'
          style="width:160px;"
          placeholder='昵称或店铺名或userId'
        ></a-input>
        <a-input
            allowClear
            v-model="params.idNumber"
            size='small'
            style="width:160px; margin-left: 10px"
            placeholder='用户IdNumber搜索'
        ></a-input>
        <!-- <a-input v-model="params.userId" size='small' style="width:150px;" placeholder='用户userId'></a-input> -->
        <a-input
          allowClear
          v-model="params.userPhone"
          size='small'
          style="width:150px;margin-left:10px;"
          placeholder='请输入手机号'
        ></a-input>
        <a-range-picker
          v-model="times"
          :placeholder="['注册','日期筛选']"
          size='small'
          style="width:250px;margin:5px;"
        />
        <a-range-picker
          v-model="lgTimes"
          :placeholder="['最后登录','日期筛选']"
          size='small'
          style="width:250px;margin:5px;"
        />
        <a-select
          allowClear
          v-model="params.fansSort"
          size='small'
          style="width:100px;margin:5px;"
          placeholder='粉丝'
        >
          <a-select-option :value='0'>正序</a-select-option>
          <a-select-option :value='1'>倒序</a-select-option>
        </a-select>

        <a-select
          allowClear
          v-model="params.availableMoneySort"
          size='small'
          style="width:100px;margin:5px;"
          placeholder='账户金额'
        >
          <a-select-option :value='0'>正序</a-select-option>
          <a-select-option :value='1'>倒序</a-select-option>
        </a-select>
        <a-select
          allowClear
          v-model="params.freezingMoneySort"
          size='small'
          style="width:100px;margin:5px;"
          placeholder='担保金额'
        >
          <a-select-option :value='0'>正序</a-select-option>
          <a-select-option :value='1'>倒序</a-select-option>
        </a-select>

        <a-select
          allowClear
          v-model="params.otherLikeSort"
          size='small'
          style="width:100px;margin:5px;"
          placeholder='被赞'
        >
          <a-select-option :value='0'>正序</a-select-option>
          <a-select-option :value='1'>倒序</a-select-option>
        </a-select>

        <a-select
          allowClear
          v-model="params.loginType"
          size='small'
          style="width:100px;margin:5px;"
          placeholder='注册类型'
        >
          <a-select-option value="dq-platform">小程序</a-select-option>
          <a-select-option value="dq-app">App</a-select-option>
          <a-select-option value="dq-Album">计泉</a-select-option>
          <a-select-option value="dq-gzh-dqqb">公众号</a-select-option>
        </a-select>

        <a-select
          allowClear
          v-model="params.source"
          size='small'
          style="width:100px;margin:5px;"
          placeholder='来源渠道'
        >
          <a-select-option value="quanhai">泉海</a-select-option>
        </a-select>

        <a-button
          @click="search"
          size='small'
          type="primary"
          style="margin-left:10px;"
        >搜索</a-button>

      </div>
    </div>
    <div class="table-content">
      <a-table
        @change="changePage"
        :pagination='pagination'
        :loading='loading'
        :columns="columns"
        :data-source="data"
        :rowKey="(record,n)=>n"
      >
        <div slot="Image" slot-scope="row" class="flex-colum-start-center">
          <div class="w-50 h-50 user-img flex-center-center" >
            <img class="w-50 h-50" :src="row.userAvatar" alt="">
            <div class="vip-tag" v-if="row.userLevel">
              <img class="vip-img" :src="userVipIcon[row.userLevel]" />
            </div>
          </div>
          <div class="mt-10"><a-button size="small" @click="handleShowUserOfferPriceList(row)">出价记录</a-button></div>
        </div>

        <div slot="rank" slot-scope="row">
          <span v-if="row.isAttention == '0'" style='color:red'>{{row.userName}}</span>
          <span v-else>{{row.userName}}</span>
          <div>
            <a-tag
              closable
              @close='confirmDelRankTag($event,_item,_index)'
              v-for="(_item,_index) in row.ranks"
              :key="_index"
              @click="editRankTag(_item,_index)"
            >{{_item.rankName}}</a-tag>
          </div>

          <div>
            <a-button
              @click="editRankTag(row)"
              size='small'
            >+头衔</a-button>
          </div>

        </div>
        <div slot="userPhone" slot-scope="row">
          <span @click="handleShowUserPhone(row)" style="cursor: pointer" :style="{color: row.isGrayUser == 1 ? 'gray' : 'blue'}">{{ row.userPhone  }}</span>
        </div>
        <div slot="idSlot" slot-scope="row">
          <a @click="openLog(row)">{{row.userId}}</a>
        </div>
        <div slot="Num" slot-scope="row">
          <a @click="openPage(row)">{{row.onShelfProduct}}</a>
        </div>
        <div slot="setup" slot-scope="row">
          <a @click="muteAction(row, 'show')" v-if="row.isMute == '0'" style='margin-left:5px'>禁言</a>
          <a-popconfirm
            title="确认要执行操作吗?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="muteAction(row)"
          ><a v-if="row.isMute != '0'" style='margin-left:5px'>取消禁言</a></a-popconfirm>
          <a v-if="row.userStatus == 2" @click="blackAction(row)" style='margin-left:5px'>取消拉黑</a>
          <a v-if="row.userStatus == 1" @click="openBlackList(row)" style='margin-left:5px'>拉黑</a>
          <a @click="openBlackPenalize(row)" style='margin-left:5px'>惩罚</a>
        </div>
<!--    连连钱包/斗泉钱包    -->
        <div slot="itemDqAndLianLianPriceSlot" slot-scope="row">
          <div class="color-blue font-weight-bold">斗泉钱包：￥{{ row.availableMoney }}</div>
          <div class="color-green font-weight-bold mt-10" v-if="row.lianLianMoney">连连钱包：￥{{ row.lianLianMoney }}</div>
          <div class="mt-10" v-if="row.lianLianMoney">
            <a-button  @click="handleShowLianLianChangePrice(row)" type="danger" size='small'>代扣扣除</a-button>
          </div>
          <div class="mt-10" v-if="row.availableMoney && row.availableMoney > 0 || row.lianLianMoney && row.lianLianMoney > 0 ">
            <a-button  @click="handleShowDrawLine(row)" type="primary" size='small'>明细</a-button>
          </div>
        </div>
      </a-table>
    </div>
    <a-modal
      centered
      :width='500'
      v-model="showModal"
      @ok='checkForms'
      @cancel='cancel'
    >
      <div style="height:200px;overflow-y:auto;">
        <a-form-model
          v-bind="layout"
          :model='forms'
          :rules='rules'
          ref="ruleForm"
        >
          <a-form-model-item
            label='名称'
            prop='rankName'
          >
            <a-input
              placeholder='请输入名称'
              v-model="forms.rankName"
            ></a-input>
          </a-form-model-item>

          <a-form-model-item label='图标'>
            <a-input
              v-model="forms.rankIcon"
              allowClear
              @paste.stop.prevent="untils.pasteImg($event,2,parsetCallback)"
            >
              <a-upload
                slot='addonBefore'
                name="file"
                :showUploadList='false'
                :action="ip+'/file/upload'"
                @change="handleChange($event,forms)"
              >
                <a>
                  <a-icon type="upload" />
                </a>
              </a-upload>
            </a-input>
          </a-form-model-item>

          <a-form-model-item label='排序'>
            <a-input
              placeholder='请输入序号'
              v-model="forms.rankSort"
            ></a-input>
          </a-form-model-item>

        </a-form-model>
      </div>
    </a-modal>
    <a-modal
      v-model="userlogshow"
      :width='450'
      centered
      :footer='null'
      @cancel='cancel'
    >
      <div :style="'max-height:400;overflow:auto;'">
        <div class="modal-items">
          <div
            class="title"
            align='center'
          >登录信息</div>

          <div class="modal-content modal-express">

            <div class="basic-item">登录次数：{{userLog.loginCount}}</div>
            <div class="basic-item">客户端类型：{{userLog.appType||''}}</div>
            <div class="basic-item">来源类型：{{userLog.bizType||''}}</div>
            <div class="basic-item">应用版本：{{userLog.appVersion||''}}</div>
            <div class="basic-item">登录IP：{{userLog.ip||''}}</div>
            <div class="basic-item">设备型号：{{userLog.deviceModel||''}}</div>
            <div class="basic-item">系统版本：{{userLog.appSystem||''}}</div>

          </div>
        </div>
      </div>
    </a-modal>
    <a-modal
      v-model="blackpenalizeshow"
      title="惩罚"
      ok-text="确认"
      cancel-text="取消"
      @cancel='resetForms'
      @ok="hideModal"
    >
      <a-form-model
        ref="ruleForm"
        :model="forms"
        :rules="rules"
        v-bind="layout"
      >
        <a-form-model-item
          label="惩罚标题"
          prop="penalizeTitle"
        >
          <a-input
            placeholder='惩罚标题'
            v-model="blackPenalizeForms.penalizeTitle"
          ></a-input>
        </a-form-model-item>

        <a-form-model-item
          label="惩罚内容"
          prop="penalizeContent"
        >
          <a-textarea
            placeholder='惩罚内容'
            v-model="blackPenalizeForms.penalizeContent"
          ></a-textarea>
        </a-form-model-item>

        <a-form-model-item
          label="惩罚天数"
          prop="days"
        >
          <a-select
            v-model="blackPenalizeForms.days"
            :filter-option="untils.filterOption"
            showSearch
            placeholder='请选择'
          >
            <a-select-option value="30">1个月</a-select-option>
            <a-select-option value="90">3个月</a-select-option>
            <a-select-option value="180">6个月</a-select-option>
            <a-select-option value="365">12个月</a-select-option>
            <a-select-option value="3650">永久</a-select-option>
          </a-select>
        </a-form-model-item>

      </a-form-model>
    </a-modal>
    <a-modal
      v-model="blackshow"
      title="拉黑"
      ok-text="确认"
      cancel-text="取消"
      @cancel='resetForms'
      @ok="blackConfirm"
    >
      <a-form-model
        ref="ruleForm"
        :model="forms"
        :rules="rules"
        v-bind="layout"
      >
        <a-form-model-item
          label="拉黑原因"
          prop="penalizeContent"
        >
          <a-textarea
            placeholder='拉黑原因'
            v-model="blackForms.remark"
          ></a-textarea>
        </a-form-model-item>

      </a-form-model>
    </a-modal>
<!--  连连钱包扣除  -->
    <!--  转账  -->
    <a-modal
        v-model="lianLianChangeShowModal"
        title="连连钱包代扣扣除"
        @cancel='handleLianLianChangeCancel'
        @ok="handleLianLianChangeConfirm"
    >
      <a-form-model>
        <a-form-model-item label="扣除金额" prop="money" >
          <a-input placeholder='请输入扣除金额' type="number" v-model="lianLianChangeForms.deductMoney" ></a-input>
        </a-form-model-item>
        <a-form-model-item label="扣除描述" prop="remarks" >
          <a-textarea
              v-model="lianLianChangeForms.deductDesc"
              placeholder="扣除描述"
              :rows="4" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

<!--  明细  -->
    <UserDrawLine ref="userDrawLineEl"/>
<!--  禁言  -->
    <UserBanSpeakPopup ref="userBanSpeakPopupEl" @addSuccess="handleAddSuccessBanSpeak"/>
<!--  出价记录  -->
    <UserOfferPriceListPopup ref="userOfferPriceListPopupEl" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import untils from "@/untils"
import UserDrawLine from "@/views/cmsPage/dqPlatform/components/UserDrawLine/index.vue"
import UserBanSpeakPopup from "@/views/cmsPage/dqPlatform/components/UserBanSpeakPopup/index.vue"
import {userBanSpeakApi, userStudyApi} from "@/views/cmsPage/dqPlatform/_apis"
import {user_vip_level_icon} from "@/views/cmsPage/dqPlatform/_data"
import UserOfferPriceListPopup from "@/views/cmsPage/dqPlatform/components/UserOfferPriceListPopup/index.vue"
export default {
  components: {UserDrawLine, UserBanSpeakPopup, UserOfferPriceListPopup},
  data() {
    return {
      userVipIcon: user_vip_level_icon,
      untils,
      lianLianChangeShowModal: false,
      lianLianChangeForms: {
        userId: undefined,
        deductMoney: undefined,
        deductDesc: undefined,
      },
      columns: [
        // {title:'id',dataIndex:'userId'},
        {
          title: "id",
          align: "center",
          scopedSlots: { customRender: "idSlot" },
        },
        {
          title: "昵称",
          align: "center",
          scopedSlots: { customRender: "rank" },
        },
        {
          title: "头像",
          scopedSlots: { customRender: "Image" },
        },
        // {title:'手机号',dataIndex:'userPhone'},
        {
          title: "手机号",
          align: 'center',
          scopedSlots: { customRender: "userPhone" },
        },

        {
          title: "注册类型",
          align: "center",
          dataIndex: "loginType",
          customRender: (text, row, index) => {
            const { loginType } = row;
            const { deviceType } = row;
            if (loginType == "dq-platform") {
              return <span style="color:green">小程序</span>;
            }
            if (loginType == "dq-app" || loginType == "dq-phone") {
              if (deviceType == 2) {
                return <span style="color:red">App-Android</span>;
              }
              if (deviceType == 3) {
                return <span style="color:red">App-iOS</span>;
              }
            }
            if (loginType == "dq-Album") {
              return <span style="color:blue">记泉</span>;
            }
            if (loginType == "dq-gzh-dqqb") {
              return <span style="color:black">公众号</span>;
            }
          },
        },
        {
          title: "来源",
          align: "center",
          dataIndex: "source",
          customRender: (text, row, index) => {
            const { source } = row;
            if (source == "quanhai") {
              return <span style="color:green">泉海</span>;
            } else {
              return <span>{source}</span>;
            }
          },
        },
        { title: "注册时间", align: "center", dataIndex: "createTime" },
        { title: "最近活跃时间", align: "center", dataIndex: "lastLoginTime" },
        { title: "粉丝", align: "center", dataIndex: "fansNum" },
        { title: "斗泉钱包/连连钱包", align: "center", scopedSlots: {customRender: 'itemDqAndLianLianPriceSlot'}, width: 200 },
        // { title: "担保金额", align: "center", dataIndex: "freezingMoney" },
        {
          title: "拉黑/禁言",
          align: "center",
          dataIndex: "isMute",
          customRender: (text, row, index) => {
            const { isMute } = row;
            const { userStatus } = row;
            if (isMute == 0 && userStatus == 1) {
              return <span>正常</span>;
            }

            if (isMute == 1 && userStatus == 1) {
              return <span style="color:red">已禁言</span>;
            }

            if (isMute == 0 && userStatus == 2) {
              return <div>
                        <div style="color:red">已拉黑</div>
                        <div style="color:red">{row.blackRemark}</div>
                     </div>;
              
            }
            if (isMute == 1 && userStatus == 2) {
              return <span style="color:red">已拉黑/已禁言</span>;
            }
            return;
          },
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      times: [],
      lgTimes: [],
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      forms: {
        rankIcon: "",
        rankName: "",
      },
      blackPenalizeForms: {},
      blackForms: {},
      rules: {
        rankIcon: [{ required: true, message: "图标", trigger: "blur" }],
        rankName: [{ required: true, message: "标题", trigger: "blur" }],
      },
      showModal: false,
      visible: false,
      userlogshow: false,
      blackpenalizeshow: false,
      blackshow: false,
      userLog: {},
      params: {
        pageNum: 1,
        pageSize: 10,
        searchName: "",
        userPhone: "",
        createStartTime: "",
        createEndTime: "",
        lgStartTime: "",
        lgEndTime: "",
        idNumber: undefined
      },
      pagination: {
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      loading: false,
      clicked: false,
      hovered: false,
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  created() {
    const {userId} = this.$route.query
    this.$set(this.params, 'searchName', userId)
    this.getData(this.params);
  },
  methods: {
    /** 出价记录 */
    async handleShowUserOfferPriceList(row) {
      this.$refs.userOfferPriceListPopupEl.show(row.userId)
    },
    /** 禁言成功 */
    handleAddSuccessBanSpeak() {
      this.getData(this.params)
    },
    /** 提现钱包明细 */
    handleShowDrawLine(row) {
      this.$refs.userDrawLineEl.show(row)
    },
    /** 确定代扣扣除 */
    async handleLianLianChangeConfirm() {
      if (!this.lianLianChangeForms.deductMoney) return this.$message.warning('扣除金额不能为空')
      if (!this.lianLianChangeForms.deductDesc) return this.$message.warning('扣除描述不能为空')
      const res = await this.axios('/dq_admin/userAccount/deductLianLianAccount', {
        params: this.lianLianChangeForms
      })
      if (res.status !== '200') return
      this.$message.info('扣除成功')
      await this.getWalletPrice({userId: this.lianLianChangeForms.userId})
      this.handleLianLianChangeCancel()
    },
    /** 代扣扣除 */
    handleShowLianLianChangePrice(row) {
      this.lianLianChangeForms.userId = row.userId
      this.lianLianChangeShowModal = true
    },
    /** 取消代扣扣除 */
    handleLianLianChangeCancel() {
      this.lianLianChangeForms = {
        userId: undefined,
        deductMoney: undefined,
        deductDesc: undefined,
      }
      this.lianLianChangeShowModal = false
    },
    /** 获取钱包余额 */
    async getWalletPrice(row) {
      const res = await this.axios('/dq_admin/userWithdrawDetail/getUserAccountOrLianlianAccount', {
        params: { userId: row.userId }
      })
      if (res.status !== '200') return
      if (res.data) {
        const tempList = JSON.parse(JSON.stringify(this.data))
        tempList.forEach(el => {
          if (el.userId === row.userId) {
            el.lianLianMoney = res.data.lianlianAccountMoney
          }
        })
        this.data = tempList
      }
    },
    /** 点击电话获取详细电话 */
    async handleShowUserPhone(row) {
      const res = await this.axios.get('/dq_admin/user/getUserPhone', {
        params: {
          userId: row.userId
        }
      })
      if (res.status !== '200') return
      this.data.forEach(el => {
        if (el.userId === row.userId) {
          el.userPhone = res.data.userPhone
        }
      })
    },
    async getData(params) {
      this.loading = true;
      const res = await this.axios("/dq_admin/user/getUserList", {
        params: params,
      });
      this.loading = false;
      const { records, total } = res.data;
      this.data = records;
      this.pagination.total = total;
      for (const el of this.data) {
        await this.getWalletPrice(el);
      }
    },
    search() {
      const result = this.times.map((row) => {
        const time = new Date(row._d).toLocaleDateString().replace(/\//g, "-");
        return time;
      });
      if (result && result.length > 0) {
        [this.params.createStartTime, this.params.createEndTime] = [...result];
      }

      const lgresult = this.lgTimes.map((row) => {
        const time = new Date(row._d).toLocaleDateString().replace(/\//g, "-");
        return time;
      });
      if (lgresult && lgresult.length > 0) {
        [this.params.lgStartTime, this.params.lgEndTime] = [...lgresult];
      }
      this.params.pageNum = 1;
      this.getData(this.params);
    },

    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.limit = pageSize;
      this.getData(this.params);
    },
    openPage(row) {
      const routeData = this.$router.resolve({
        path: "/cms/currencymannage/goods",
        query: {
          userName: row.userId || 100,
        },
      });
      window.open(routeData.href, "_blank");
    },
    async muteAction(row, type) {
      const userId = row.userId;
      if (type === 'show') {
        await this.$refs.userBanSpeakPopupEl.show(userId)
      } else {
        const res = await userBanSpeakApi({userId, timeSecond: 0})
        if (res.status !== '200') return
        this.$message.success(res.message);
        await this.getData(this.params);
      }
    },

    blackAction(row) {
      this.blackForms.userId = row.userId;
      // const res = await this.axios.post("/dq_admin/user/penalize", this.blackForms);
      this.axios.post("/dq_admin/user/black", this.blackForms).then((res) => {
        this.getData(this.params);
        this.$message.success(res.message);
      });
    },

    editRankTag(row, index) {
      this.showModal = true;
      this.forms.userId = row.userId;
      const { id = null } = row;
      this.IO = id ? 1 : 0;
      if (this.IO == 1) {
        //updata
        this.forms = JSON.parse(JSON.stringify(row));
      } else {
        //add
        Object.assign(this.forms, { type: "search" });
      }
    },

    handleChange(res, forms) {
      const { response } = res.file;
      const { data } = response || { data: null };
      if (data) {
        forms.rankIcon = data;
      }
    },

    resetForms(){
      this.cancel();
    },
    cancel() {
      this.userLog = {};
      this.forms = { rankIcon: "", userId: "" };
      this.blackPenalizeForms = {};
      this.blackForms = {};
      this.$refs.ruleForm.resetFields();
    },
    checkForms() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit(this.forms);
        } else {
          return false;
        }
      });
    },
    async submit() {
      const res = await this.axios.post(
        "/dq_admin/userRank/addOrEdit",
        this.forms
      );
      if (res.status == 200) {
        const { message } = res;
        this.$message.success(message);
        this.getData(this.params);
        this.showModal = false;
        this.cancel();
      }
    },
    remove(row) {
      const id = row.id;
      this.axios("/dq_admin/userRank/delById", {
        params: { id: id },
      }).then((res) => {
        this.getData(this.params);

        this.$message.success(res.message);
      });
    },
    confirmDelRankTag(e, row, index) {
      e.preventDefault();
      this.$confirm({
        title: "确认删除吗?",
        content: "",
        onOk: () => {
          this.remove(row);
        },
        onCancel() {},
      });
    },
    handleClickChange(visible) {
      this.clicked = visible;
      this.hovered = false;
    },

    openLog(row) {
      this.userlogshow = true;
      this.axios("/dq_admin/userLog/detail", {
        params: { userId: row.userId },
      }).then((res) => {
        this.userLog = res.data;
      });
    },

    openBlackPenalize(row) {
      this.blackpenalizeshow = true;
      this.blackPenalizeForms.userId = row.userId;
    },

    openBlackList(row) {
      this.blackshow = true;
      this.blackForms.userId = row.userId;
    },

    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.blackpenalizeSubmit(this.blackPenalizeForms);
        } else {
          return false;
        }
      });
    },


    async blackpenalizeSubmit() {
      const res = await this.axios.post("/dq_admin/userShopPenalize/addPenalize", this.blackPenalizeForms);
      const { message } = res;
      if (res.status == 200) {
        this.$message.success(message);
        this.getData(this.params);
        this.blackpenalizeshow = false;
        this.cancel();
      } else {
        this.$message.success(message);
      }
    },

    async blackConfirm(){
      const res = await this.axios.post("/dq_admin/user/black", this.blackForms);
      const { message } = res;
      if (res.status == 200) {
        this.$message.success(message);
        this.getData(this.params);
        this.blackshow = false;
        this.cancel();
      } else {
        this.$message.success(message);
      }
      
    }
  },
};

function d(a, b) {
  // setTimeout((

  // ),1000);
  // this.params.fansSort = 0
  this.getData(this.params);
}
</script>

<style lang="scss" scoped>
.vip-tag {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  padding: 10px;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .5);
  .vip-img {
    width: 29px;
    height: 16px;
  }
}
.user-img {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}
.search-attr {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}
.take-box {
  width: 100%;
  max-height: 700px;
}
</style>