<template>
  <a-drawer title="出价记录" placement="right" width="80%" :visible="showPopup" @close="handleClosePopup">
<!--  拍品ID搜索  -->
    <div class="flex-start-center">
      <a-input allowClear v-model="productId" class="w-200" placeholder="请输入拍品ID搜索"></a-input>
      <a-button class="ml-20" type="primary" @click="handleSearch">搜索</a-button>
    </div>
<!--  当前出价列表  -->
    <a-divider>当前出价记录</a-divider>
    <CurrentOfferPriceTableList
        :list="currentOfferPriceList"
        :pagination="currentPagination"
        @changePage="handleChangeCurrentOrHistoryOfferPricePage($event, 'current')"
    />
<!--  历史出价记录  -->
    <a-divider class="mt-20">历史出价记录</a-divider>
    <HistoryOfferPriceTableList
        :list="historyOfferPriceList"
        :pagination="historyPagination"
        @changePage="handleChangeCurrentOrHistoryOfferPricePage($event, 'history')"
    />
  </a-drawer>
</template>
<script>
import {
  getUserOfferPriceByCurrentApi,
  getUserOfferPriceByHistoryApi
} from "@/views/cmsPage/dqPlatform/components/UserOfferPriceListPopup/_apis"
import CurrentOfferPriceTableList
  from "@/views/cmsPage/dqPlatform/components/UserOfferPriceListPopup/_components/CurrentOfferPriceTableList/index.vue"
import HistoryOfferPriceTableList
  from "@/views/cmsPage/dqPlatform/components/UserOfferPriceListPopup/_components/HistoryOfferPriceTableList/index.vue"

export default {
  components: {CurrentOfferPriceTableList, HistoryOfferPriceTableList},
  data() {
    return {
      showPopup: false,
      productId: undefined,
      currentOfferPriceParams: {
        userId: undefined,
        productId: undefined,
        pageSize: 5,
        pageNum: 1,
      },
      currentOfferPriceList: [], // 当前出价记录
      currentPagination: {
        total: 0,
        current: 1,
        pageSize: 5,
        showTotal: (total) => `共${total}条`,
      },
      historyOfferPriceParams: {
        userId: undefined,
        productId: undefined,
        pageSize: 5,
        pageNum: 1,
      },
      historyOfferPriceList: [], // 历史出价记录
      historyPagination: {
        total: 0,
        current: 1,
        pageSize: 5,
        showTotal: (total) => `共${total}条`,
      },
    }
  },
  methods: {
    async show(userId) {
      this.currentOfferPriceParams.userId = userId
      this.historyOfferPriceParams.userId = userId
      await this.getCurrentOfferPriceList()
      await this.getHistoryOfferPriceList()
      this.showPopup = true
    },
    /** 获取当前出价记录 */
    async getCurrentOfferPriceList() {
      this.$loading.show()
      const res = await getUserOfferPriceByCurrentApi(this.currentOfferPriceParams)
      this.$loading.hide()
      if (res.status !== '200') return
      this.currentOfferPriceList = res.data.records
      this.currentPagination.total = res.data.total
    },
    /** 获取历史出价记录 */
    async getHistoryOfferPriceList() {
      this.$loading.show()
      const res = await getUserOfferPriceByHistoryApi(this.historyOfferPriceParams)
      this.$loading.hide()
      if (res.status !== '200') return
      this.historyOfferPriceList = res.data.records
      this.historyPagination.total = res.data.total
    },
    /** 翻页--历史出价记录 */
    async handleChangeCurrentOrHistoryOfferPricePage(page, type) {
      const { current } = page
      if (type === 'current') {
        this.$set(this.currentPagination, "current", current);
        this.$set(this.currentOfferPriceParams, "pageNum", current);
        await this.getCurrentOfferPriceList()
      } else if (type === 'history') {
        this.$set(this.historyPagination, "current", current);
        this.$set(this.historyOfferPriceParams, "pageNum", current);
        await this.getHistoryOfferPriceList()
      }
    },
    /** 搜索 */
    async handleSearch() {
      this.currentOfferPriceParams.productId = this.productId
      this.historyOfferPriceParams.productId = this.productId
      this.$set(this.currentPagination, "current", 1);
      this.$set(this.currentOfferPriceParams, "pageNum", 1);
      this.$set(this.historyPagination, "current", 1);
      this.$set(this.historyOfferPriceParams, "pageNum", 1);
      await this.getCurrentOfferPriceList()
      await this.getHistoryOfferPriceList()
    },
    /** 关闭弹窗 */
    handleClosePopup() {
      this.$set(this.currentPagination, "current", 1);
      this.$set(this.currentOfferPriceParams, "pageNum", 1);
      this.$set(this.historyPagination, "current", 1);
      this.$set(this.historyOfferPriceParams, "pageNum", 1);
      this.productId = undefined
      this.showPopup = false
    }
  }
}
</script>