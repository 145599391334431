import instance from "@/axios"


/** 提交用户消息模版推送信息 */
export const getUserWhitelistMessageTemplateApi = async (params) => {
    return instance({
        url: '/dq_admin/user/rateSpotCheckInform',
        method: 'get',
        params: params
    })
}

/** 获取消息模版详情信息 */
export const getMessageTemplateDetailInfoApi = async (params) => {
    return instance({
        url: '/dq_admin/user/rateSpotCheckInformDesc',
        method: 'get',
        params: params
    })
}