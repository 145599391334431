<template>
  <a-table
      :columns="tableList"
      :pagination="pagination"
      :data-source="list"
      :rowKey="(record, index) => index"
      @change="changePage"
  >
<!--  拍品ID  -->
    <div slot="itemProductIdSlot" slot-scope="row">
      <div class="color-blue font-weight-bold cur-pot" @click="handleGoToAuctionList(row)">{{ row.productId }}</div>
    </div>
<!--  拍品名称  -->
    <div slot="itemProductNameSlot" slot-scope="row">
      <div class="color-black font-weight-bold">{{ row.productName }}</div>
    </div>
<!--  金额相关  -->
    <div slot="itemProductPriceAboutSlot" slot-scope="row">
      <div class="font-weight-bold color-black">拍品当前价格：【<span class="color-red">￥{{ row.currentFinalPrice }}</span>】</div>
      <div class="font-weight-bold color-black mt-20">
        用户最新出价：
        【
        <span class="color-red">￥{{ row.finalPrice }}</span>
        ---
        <span :class="row.isAdvanced ? 'color-green' : 'color-gray'">{{ row.isAdvanced ? '领先' : '落后' }}</span>
        】
      </div>
    </div>
  </a-table>
</template>
<script>
import {
  currentOrHistoryOfferPriceTableColumns
} from "@/views/cmsPage/dqPlatform/components/UserOfferPriceListPopup/_data"
import {goToOtherPage} from "@/untils/otherEvent"

export default {
  props: ['pagination', 'list'],
  emits: ['changePage'],
  data() {
    return {
      tableList: currentOrHistoryOfferPriceTableColumns,
    }
  },
  computed: {

  },
  methods: {
    /** 去往拍品列表页面 */
    handleGoToAuctionList(row) {
      goToOtherPage(`/cms/currencymannage/auctiongoods?productName=${row.productId}`)
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>