<template>
  <a-modal v-model="showPopup" title="用户备注" :width="500" @cancel="handleCancel" @ok="handleConfirm">
    <div class="popup-content">
      <a-form-model ref="ruleForm" :model="params" v-bind="layout">
        <a-form-item label="">
          <a-textarea
              v-model="params.remark"
              placeholder="用户备注"
              :rows="4"
          />
        </a-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import {postEditorUserRemarkApi} from "@/views/cmsPage/dqPlatform/_apis"

export default {
  data() {
    return {
      showPopup: false,
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      params: {
        userId: undefined,
        remark: undefined,
      },
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data) {
      this.params.userId = data.userId
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        userId: undefined,
        remark: undefined,
      }
      this.showPopup = false
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.params.remark) return this.$message.warn("请填写描述")
      this.$loading.show()
      const res = await postEditorUserRemarkApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('增加备注成功')
      this.$emit('editorSuccess', this.params.userId)
      this.handleCancel()
    },
  }
}
</script>