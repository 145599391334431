var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "用户备注", width: 500 },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticClass: "popup-content" },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "ruleForm", attrs: { model: _vm.params } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-item",
                { attrs: { label: "" } },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "用户备注", rows: 4 },
                    model: {
                      value: _vm.params.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "remark", $$v)
                      },
                      expression: "params.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }