var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "换绑手机号", width: 500 },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticClass: "popup-content" },
        [
          _c(
            "a-form-model",
            _vm._b({}, "a-form-model", _vm.layout, false),
            [
              _c(
                "a-form-item",
                { attrs: { label: "新手机号" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入新绑定手机号", type: "tel" },
                    model: {
                      value: _vm.params.phoneNo,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "phoneNo", $$v)
                      },
                      expression: "params.phoneNo"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }